import axios from "axios";
import { useState, useEffect, useRef } from "react";
import { Grid, Alert, Button, Typography, Snackbar } from "@mui/material";
import {
  getPOSProducts,
  storePosTransaction,
  storeUtangTransaction,
} from "../../../services/PosService";
import ChargeModal from "./components/ChargeModal";
import ReceiptModal from "./components/ReceiptModal";
import { QuantityDialog } from "./components/QuantityDialog";

// global css
import UtangModal from "./components/UtangModal";
import { DiscountDialog } from "./components/DiscountDialog";
import PosProducts from "./sections/PosProducts";
import PosDetails from "./sections/PosDetails";
import PosSuccess from "./sections/PosSuccess";
import AsyncStorage from "@react-native-async-storage/async-storage";
import SearchProduct from "./components/SearchProduct";
import CategoryChips from "./sections/CategoryChips";
import PosProductSkeleton from "./sections/PosProductSkeleton";

export default function Pos() {
  let cancelTokenSource;
  const [snackVariant, setSnackVariant] = useState("success");
  const [openSnack, setOpenSnack] = useState(false);
  const [errMsg, setErrMsg] = useState("");

  const tableRef = useRef(0);
  const [storeId, setStoreId] = useState(0);
  const [fetching, setFetching] = useState(true);
  const [barcodeMode, setBarcodeMode] = useState(false);
  const [filter, setFilter] = useState(0);
  const [cart, setCart] = useState([]);
  const [selected, setSelected] = useState(null);
  const [open, setOpen] = useState(false);
  const [products, setProducts] = useState([]);
  const [openChargeModal, setChargeModalOpen] = useState(false);
  const [openReceiptModal, setReceiptModalOpen] = useState(false);
  const [openUtangModal, setUtangModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [receipt, setReceipt] = useState(null);
  const [paymentAmount, setPaymentAmount] = useState(0);
  const [addDiscount, setAddDiscount] = useState(false);
  const [discount, setDiscount] = useState(0);
  const [isSuccessTransaction, setSuccessTransaction] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    const unloadCallback = (event) => {
      event.preventDefault();
      event.returnValue = "";
      return "";
    };

    window.addEventListener("beforeunload", unloadCallback);
    return () => window.removeEventListener("beforeunload", unloadCallback);
  }, []);

  const loadMore = async () => {
    const newRows = Number(rowsPerPage) + 100;
    setRowsPerPage(newRows);
    getProductList(searchText, storeId, newRows, filter);
  };

  const handleOpenChargeModal = () => {
    setIsLoading(!isLoading);
    setChargeModalOpen(!openChargeModal);
  };
  const handleOpenUtangModal = () => {
    setIsLoading(!isLoading);
    setUtangModalOpen(!openUtangModal);
  };

  const subTotal = Number(
    cart.reduce((accumulator, object) => {
      return accumulator + object.price * object.quantity;
    }, 0)
  );

  const grandTotal = Number(subTotal - discount).toFixed(2);

  const getProductList = async (word, storeId, rowsPerPage, filter) => {
    if (word.length > 0 && word.length < 4) {
      return;
    }

    // Cancel the previous request if it exists
    if (cancelTokenSource) {
      cancelTokenSource.cancel("Request canceled due to new search");
    }

    // Create a new CancelToken source
    cancelTokenSource = axios.CancelToken.source();

    return await getPOSProducts(
      word,
      storeId,
      rowsPerPage,
      filter,
      cancelTokenSource,
      barcodeMode
    )
      .then((response) => {
        if (response.data.length === products.length) {
          setHasNextPage(false);
        } else {
          setProducts(response.data);
        }
        return response.data;
      })
      .finally(() => {
        setFetching(false);
      });
  };
  const handlePressItem = (item) => {
    handleAddProduct(1, item);
  };

  // get products from api and set to products state
  useEffect(() => {
    const fetchProduct = async () => {
      const id = await AsyncStorage.getItem("store_id");
      getProductList("", id, rowsPerPage, filter);
      setStoreId(id);
    };
    fetchProduct();
  }, []);

  const handleAddProduct = (quantity, item) => {
    const selectedItem = item ? item : selected;
    const selectedFromCart = cart.find((row) => row.id === selectedItem.id);
    const tempCart = cart.filter((row) => row.id !== selectedItem.id);
    const accumulatedQuantity = selectedFromCart?.quantity
      ? (selectedFromCart.quantity += quantity)
      : quantity;
    const selectedItemIndex = products.findIndex(
      (row) => row.id === selectedItem.id
    );
    const tempProducts = products;
    if (!tempProducts[selectedItemIndex].orig_stock) {
      tempProducts[selectedItemIndex].orig_stock = selectedItem.available_stock;
    }
    tempProducts[selectedItemIndex].available_stock =
      selectedItem.available_stock - quantity;
    setProducts(tempProducts);
    setCart([
      ...tempCart,
      {
        ...selectedItem,
        quantity: accumulatedQuantity,
      },
    ]);
    if (item) {
      setSearchText("");
    }

    if (tableRef.current) {
      setTimeout(() => {
        tableRef.current.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        });
      }, 100);
    }
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const handleCharge = async (amount, customerId, payment_type) => {
    setPaymentAmount(Number(amount));
    setChargeModalOpen(false);
    const products = cart.map((row) => {
      return { product_id: row.id, quantity: row.quantity };
    });

    const params = {
      products: products,
      customer_id: customerId || null,
      items_quantity: products.length,
      subtotal_amount: subTotal,
      discount_value: discount,
      payment_type: payment_type,
      source: "web",
    };
    try {
      const response = await storePosTransaction(params);
      if (response?.data?.success) {
        setReceipt({ ...response?.data });
        setSuccessTransaction(!isSuccessTransaction);
      } else {
        setSnackVariant("error");
        setErrMsg("Transaction Failed! Please try again.");
        setOpenSnack(true);
        setSuccessTransaction(false);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      setSnackVariant("error");
      setErrMsg("Something went wrong!");
      setOpenSnack(true);
      setSuccessTransaction(false);
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    }
  };

  const handleUtang = async (customerId) => {
    setUtangModalOpen(false);
    const products = cart.map((row) => {
      return { product_id: row.id, quantity: row.quantity };
    });

    const params = {
      products: products,
      customer_id: customerId,
      items_quantity: products.length,
      subtotal_amount: subTotal,
      discount_value: discount,
    };

    try {
      const data = await storeUtangTransaction(params);
      setReceipt({ ...data?.data });
      setSuccessTransaction(!isSuccessTransaction);
    } catch (error) {
      Alert.error("Transaction Failed!");
    }
  };

  const handleNewTransaction = () => {
    setCart([]);
    setSuccessTransaction(false);
    setIsLoading(false);
    setDiscount(0);
    setPaymentAmount(0);
  };

  const handleReceipt = () => {
    setReceiptModalOpen(!openReceiptModal);
  };

  const handleCopyLink = async () => {
    const copyLink = receipt?.data?.transaction_receipt_link;
    if ("clipboard" in navigator) {
      await navigator.clipboard.writeText(copyLink);
    } else {
      document.execCommand("copy", true, copyLink);
    }
    alert("Copied Link!");
  };

  return fetching ? (
    <PosProductSkeleton />
  ) : (
    <Grid container>
      <Grid item sm={12} md={cart.length ? 6 : 12}>
        <CategoryChips
          searchText={searchText}
          storeId={storeId}
          rowsPerPage={rowsPerPage}
          filter={filter}
          setFilter={setFilter}
          getProductList={getProductList}
        />
        <SearchProduct
          storeId={storeId}
          rowsPerPage={rowsPerPage}
          getProductList={getProductList}
          searchText={searchText}
          setSearchText={setSearchText}
          disabled={isSuccessTransaction}
          filter={filter}
          setBarcodeMode={setBarcodeMode}
          barcodeMode={barcodeMode}
          handleAddProduct={handleAddProduct}
          setSelected={setSelected}
          products={products}
        />
        <PosProducts
          cart={cart}
          products={products}
          handlePressItem={handlePressItem}
          isSuccessTransaction={isSuccessTransaction}
        />
        <hr />
        {products && products.length > 0 ? (
          hasNextPage ? (
            <Button onClick={loadMore} fullWidth>
              Load More
            </Button>
          ) : (
            <></>
          )
        ) : (
          <Typography mt={2} align="center">
            No Record Found
          </Typography>
        )}
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          background: "#050F1A",
          borderRadius: 1,
        }}
      >
        {!isSuccessTransaction && cart.length ? (
          <PosDetails
            isLoading={isLoading}
            discount={discount}
            isSuccessTransaction={isSuccessTransaction}
            cart={cart}
            setCart={setCart}
            setProducts={setProducts}
            products={products}
            handleOpenChargeModal={handleOpenChargeModal}
            handleOpenUtangModal={handleOpenUtangModal}
            handleDiscount={() => setAddDiscount(!addDiscount)}
            tableRef={tableRef}
          />
        ) : (
          <></>
        )}
        {isSuccessTransaction && cart.length ? (
          <PosSuccess
            handleNewTransaction={handleNewTransaction}
            discount={discount}
            paymentAmount={paymentAmount}
            isSuccessTransaction={isSuccessTransaction}
            cart={cart}
            handleReceipt={handleReceipt}
            handleCopyLink={handleCopyLink}
          />
        ) : (
          <></>
        )}
      </Grid>
      <QuantityDialog
        open={open}
        onClose={() => setOpen(false)}
        onConfirm={handleAddProduct}
        item={selected}
      />
      <DiscountDialog
        currentTotal={subTotal}
        discount={discount}
        open={addDiscount}
        onClose={() => setAddDiscount(false)}
        onConfirm={(discount) => setDiscount(discount)}
      />
      <ChargeModal
        open={openChargeModal}
        handleClose={() => {
          setChargeModalOpen(false);
          setIsLoading(false);
        }}
        grandTotal={grandTotal}
        handleSubmit={handleCharge}
        setOpen={setChargeModalOpen}
      />
      <ReceiptModal
        open={openReceiptModal}
        handleClose={() => setReceiptModalOpen(false)}
        grandTotal={grandTotal}
        handleSubmit={handleCharge}
        receipt={receipt}
        handleReceipt={handleReceipt}
        change={
          receipt?.data?.payment_status ? paymentAmount - grandTotal : null
        }
      />
      <UtangModal
        open={openUtangModal}
        setOpen={setUtangModalOpen}
        handleClose={() => {
          setUtangModalOpen(false);
          setIsLoading(false);
        }}
        grandTotal={grandTotal}
        handleSubmit={handleUtang}
      />
      <Snackbar
        open={openSnack}
        autoHideDuration={3000}
        onClose={handleCloseSnack}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseSnack}
          severity={snackVariant}
          sx={{ width: "100%" }}
        >
          {errMsg}
        </Alert>
      </Snackbar>
    </Grid>
  );
}
